import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import TextField from "../../../bootstrap/TextField/index.jsx";
import {
  FirstNameGender,
  FormWrapper,
  Passenger,
  UpdatedButton,
  UserContactWrapper,
} from "./UserProfile.styles.js";

const UserProfile = ({ token }) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [countryName, setCountryName] = useState([]);
  const [values, setvalues] = useState({
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    setvalues(JSON.parse(localStorage.getItem("userProfileInfo")));
  }, [token]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => setCountryName(data.data));
  }, []);

  const getCountry = () =>
    countryName.findIndex(
      (country) => String(country.id) === String(values?.country_id)
    );

  let countries = [];
  countryName.map((item) =>
    countries.push({
      value: item.nicename,
      label: item.nicename,
      id: item.id,
    })
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("first_name", values?.first_name);
    formData.append("last_name", values?.last_name);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/profileinfo`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const result = await response.json();
      if (result?.status === "success") {
        toast.success("success");
        return;
      }
    } catch (error) {
      console.log("passenger info update error", error);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {token && (
        <>
          <Passenger>
            <label htmlFor="name">Passenger</label>
            <UserContactWrapper>
              <FirstNameGender>
                {/* <Select name="gender" id="gender" onChange={(e) => setvalues({ ...values, gender: e.target.value })}>
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
            </Select> */}
                <TextField
                  id="name"
                  name="first_name"
                  type="text"
                  placeholder="Given Name"
                  autoComplete="nope"
                  value={values?.first_name}
                  onChange={(e) =>
                    setvalues({ ...values, first_name: e.target.value })
                  }
                />
              </FirstNameGender>
              <TextField
                type="text"
                placeholder="Surname"
                name="last_name"
                value={values?.last_name}
                onChange={(e) =>
                  setvalues({ ...values, last_name: e.target.value })
                }
                autoComplete="nope"
              />
            </UserContactWrapper>
          </Passenger>
          {/* end passenger */}

          
          <UpdatedButton
            btnbgcolor={webSettingData?.buttoncolor}
            btnbghvcolor={webSettingData?.buttoncolorhover}
            btntextcolor={webSettingData?.buttontextcolor}
          >
            Update
          </UpdatedButton>
        </>
      )}
    </FormWrapper>
  );
};

export default UserProfile;
