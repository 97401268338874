import React from "react";
import TextField from "../../../bootstrap/TextField";
import {
  FirstNameAndNid,
  Passenger,
  Select,
  UserContactWrapper,
} from "./singleTravellerInformation.styles";

const SingleTravellerInformation = ({
  values,
  passengerIndex,
  handleChange,
  roundTrip,
}) => {
  return (
    <>
      
      {/* end passenger */}

      
    </>
  );
};

export default SingleTravellerInformation;
